
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
  }
})
export default class SortingAlgorithms extends Vue {
  inputVal = 0.2;
  outputVal: number[] = [1, 2];

  all: number[] = [];
  active: number[] = [];

  farey (num: number) : void {
    // E.g. num = 14.9874
    // [14, 9874]
    var value = num.toString().split('.')

    // 14
    var integer = value[0]

    // This string manipulation is needed becasue of computers' inate float rounding errors
    // 0.9874
    var decimalVal = +(num - +integer).toFixed(value[1].length)

    // 0.9874, 0/1, 1/1
    var newVal = this.completeFarey(decimalVal, [0, 1], [1, 1])

    /* (5000 * 14) + 4937 / 5000
    *     70000    + 4937 / 5000
    *         74937       / 5000
    *             14.9874
    */
    this.outputVal = [(newVal[1] * +integer) + newVal[0], newVal[1]] // (5000 * 14)
  }

  completeFarey (target: number, start: number[], end: number[]) : number[] {
    var newVal = this.calculate(start, end)
    if (target === (newVal[0] / newVal[1])) {
      return newVal
    } else if (target > (newVal[0] / newVal[1])) {
      return this.completeFarey(target, newVal, end)
    } else {
      return this.completeFarey(target, start, newVal)
    }
  }

  calculate (start: number[], end: number[]) : number[] {
    return [start[0] + end[0], start[1] + end[1]]
  }
}
